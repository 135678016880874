import {
  getCertificationProgress,
  getCertRequirements,
  logStartCertificate,
  requestAudit as requestAuditAPI,
  submitDeed,
  updateCertificationProgress,
} from "api";
import { getCertificationInfo } from "api/functions/certification/info";
import { CERTIFICATION_STEP_TITLES } from "variables";

const getRequirementsForLevel = async (level) => {
  if (!level) {
    return [];
  }
  return (await getCertRequirements(level))?.data;
};

/**
 * Check if user input to certification step is valid and can be proceed
 *
 * @param {*} id certification id
 * @param {*} step current step
 * @param {*} done latest finished step
 * @param {*} data user input
 * @param {*} onSuccess success callback
 * @param {*} onFailure failure callback
 * @returns
 */
const assessCertificationStep = (
  id,
  step,
  done,
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
) => {
  if (step <= done) {
    // console.log(step, done);
    // console.log("Already done.");
    onSuccess();
    return;
  }
  if (step - done > 1) {
    onFailure();
    return;
  }
  if (step === 1 || step === 5) {
    if (step === 1) {
      // log cert start
      logStartCertificate({ progressId: id });
    }
    return quickProceedCertificationStep(id, step, onSuccess, onFailure);
  } else if (step === 2) {
    return assessCertificationStep2(id, data, onSuccess, onFailure);
  } else if (step === 3) {
    return assessCertificationStep3(id, data, onSuccess, onFailure);
  } else if (step === 4) {
    return assessCertificationStep4(id, onSuccess);
  } else if (step === 5) {
    return assessCertificationStep5(id, data, onSuccess, onFailure);
  } else if (step === 6) {
    onSuccess();
  }
  return null;
};

/**
 * Quick proceed to next step
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onFailure
 */
const quickProceedCertificationStep = (
  id,
  step,
  onSuccess = () => {},
  onFailure = () => {},
) => {
  if (step === 4) {
    getCertificationProgress(id).then((progress) => {
      updateCertificationProgress({
        id,
        step: getCertificationProgressNextStep(progress.level, step),
      }).then((result) => {
        if (!!result) {
          onSuccess(result);
        } else {
          onFailure();
        }
      });
    });
  } else {
    updateCertificationProgress({
      id,
      step: step + 1,
    }).then((result) => {
      if (!!result) {
        onSuccess(result);
      } else {
        onFailure();
      }
    });
  }
};

/**
 * Get the next step
 *
 * @param {*} level
 * @param {*} step
 * @returns
 */
const getCertificationProgressNextStep = (level, step) => {
  if (level < 4 && step === 4) {
    return step + 2;
  }
  return step + 1;
};

/**
 * Assess certification step 2
 * Set attestation status and person in charge of attestation
 *
 * @param {*} id certification id
 * @param {*} data user input
 * @param {*} onSuccess success callback
 * @param {*} onFailure failure callback
 * @returns
 */
const assessCertificationStep2 = (
  id,
  data,
  onSuccess = () => {},
  onFailure = () => {},
) => {
  getCertificationInfo().then((info) => {
    const items = info?.customer?.certification?.progress?.requirements?.items;
    const completedItems = items.filter((item) => isRequirementDone(item));
    if (items.length === completedItems.length) {
      updateCertificationProgress({
        id,
        step: 3,
      }).then((result) => {
        if (!!result) {
          onSuccess(result);
        } else {
          onFailure();
        }
      });
    } else {
      onFailure();
    }
  });
};

const isRequirementDone = (requirement) => {
  return (
    !!requirement.attestedBy &&
    ((requirement.attestationStatus === "NA" &&
      !!requirement.notApplicableDetails) ||
      requirement.attestationStatus === "COMPLETED")
  );
};

/**
 * Assess certification step 3
 * Create attestation deed
 *
 * @param {*} id certification id
 * @param {*} data user input
 * @param {*} onSuccess success callback
 * @param {*} onFailure failure callback
 * @returns
 */
const assessCertificationStep3 = async (
  id,
  data,
  onSuccess = () => {},
  onFailure = () => {},
) => {
  const requiredProps = [
    "role",
    "title",
    "firstName",
    "lastName",
    "email",
    "mobile",
  ];
  if (requiredProps.every((prop) => !!data[prop])) {
    const processedData = requiredProps.reduce((result, prop) => {
      return { ...result, [prop]: data[prop] };
    }, {});

    // save attestation details
    await updateCertificationProgress({
      id,
      ...processedData,
    });

    const result = await submitDeed({});

    if (!!result?.envelopeId) {
      onSuccess();
    } else {
      onFailure();
    }
  } else {
    onFailure();
  }
};

const resendSignRequest = async (id, email = undefined) => {
  const result = await submitDeed({ email });

  // save new email
  // if (!!email) {
  //   await updateCertificationProgress({
  //     id,
  //     email,
  //   });
  // }

  return result;
};

/**
 * Assess certification step 4
 * Confirm attestation is signed
 *
 * @param {*} id certification id
 * @param {*} onSuccess success callback
 * @returns
 */
const assessCertificationStep4 = (id, onSuccess = () => {}) => {
  getCertificationProgress(id).then((progress) => {
    if (progress?.attestationSigned) {
      onSuccess(progress);
    }
  });
};

/**
 * Assess certification step 4.5
 * Submit attestation for review
 *
 * @param {*} id certification id
 * @param {*} data user input
 * @param {*} onSuccess success callback
 * @param {*} onFailure failure callback
 * @returns
 */
const assessCertificationStep5 = (
  id,
  data,
  onSuccess = () => {},
  onFailure = () => {},
) => {
  // temporary
  onSuccess();
};

/**
 * Periodic progress check
 *
 * @param {*} id
 * @param {*} current
 * @param {*} updateProgress
 */
const periodicCheck = (id, level, current, updateProgress = () => {}) => {
  if (current === 4) {
    assessCertificationStep4(id, (prog) =>
      updateProgress({
        step: getCertificationProgressNextStep(level, current),
        done: getCertificationProgressNextStep(level, current) - 1,
        progressData: prog,
      }),
    );
  }
};

const getCertStepTitleFor = (progress) => {
  return CERTIFICATION_STEP_TITLES[getActualCurrentStep(progress)];
};

const getActualTotalSteps = (progress) => {
  return progress?.level < 4 ? 5 : 6;
};

const getActualCurrentStep = (progress) => {
  return progress?.level < 4 && progress?.step > 4
    ? progress?.step - 1
    : progress?.step;
};

const requestAudit = () => {
  return requestAuditAPI();
};

const getCertificationDurationInMonth = (level) => {
  if (!level) {
    return 0;
  }
  if (level > 3) {
    return 24;
  } else {
    return 12;
  }
};

const getRemainingCertificationRequirements = async (level, currentLevel) => {
  const requirementToDo = await getRequirementsForLevel(level);
  const requirementCompleted = await getRequirementsForLevel(currentLevel);
  return requirementToDo.filter(
    (r1) =>
      requirementCompleted.filter((r2) => r1.requirementId === r2.requirementId)
        .length === 0,
  );
};

const getRemainingCertificationRequirementsFromCompletedList = async (
  level,
  requirementCompleted,
) => {
  const requirementToDo = await getRequirementsForLevel(level);
  return requirementToDo.filter(
    (r1) => !requirementCompleted.includes(r1.requirementId),
  );
};

export const CertificationService = {
  assessCertificationStep,
  periodicCheck,
  resendSignRequest,
  isRequirementDone,
  getCertificationProgressNextStep,
  getCertStepTitleFor,
  getActualTotalSteps,
  getActualCurrentStep,
  getRequirementsForLevel,
  requestAudit,
  getCertificationDurationInMonth,
  getRemainingCertificationRequirements,
  getRemainingCertificationRequirementsFromCompletedList,
};
