import "./step-4.scss";

import { useEffect, useRef, useState } from "react";

import { onUpdateProgress } from "api";
import { FormInput, Spinner } from "components";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { parseDate } from "services";

import { StepIcon, StepIconArrow } from "../icons";

const DEFAULT_TIMER = "02:00";

export function Step4({
  progressData,
  resendSignRequest,
  setCurrentStep = () => {},
  done = true,
  getCurrentStep = () => {},
}) {
  const [lodgedDate, setLodgedDate] = useState("NA");
  const [resendResult, setResendResult] = useState("NA");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Awaiting signature");
  const [timer, setTimer] = useState(DEFAULT_TIMER);
  const [resendTimeout, setResendTimeout] = useState(null);
  const [sendTo, setSendTo] = useState(null);
  const [sendToTag, setSendToTag] = useState(true);
  const timerRef = useRef(null);

  const checkResendTimeout = (prog) => {
    if (!!prog.attestationResendTime) {
      if (new Date(prog.attestationResendTime) - new Date() > 0) {
        setResendTimer(prog.attestationResendTime);
      } else {
        setTimer("00:00");
        setResendTimeout(null);
      }
    }
  };

  const resend = () => {
    setLoading(true);

    if (!!resendTimeout && resendTimeout - new Date() > 0) {
      setLoading(false);
      if (!timerRef.current) {
        setResendTimer(resendTimeout);
      }
    } else {
      setResendTimeout(null);
    }

    resendSignRequest(sendTo)
      .then((res) => {
        if (!!res) {
          setResendResult("success");
        } else {
          setResendResult("error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      seconds,
      minutes,
    };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);

    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        `${minutes > 9 ? minutes : "0" + minutes}:${
          seconds > 9 ? seconds : "0" + seconds
        }`,
      );
    }
  };

  const setResendTimer = (e) => {
    setTimer(DEFAULT_TIMER);

    // set timer
    let timeout = new Date(e);

    setResendTimeout(timeout);

    if (!!timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(timeout);
    }, 1000);
    timerRef.current = id;
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    setCurrentStep(6);
  };

  useEffect(() => {
    if (!!progressData) {
      if (progressData.status === "COMPLETED") {
        setStatus("Signed");
      }
      if (!!progressData.email) {
        setSendTo(progressData.email);
      }

      checkResendTimeout(progressData);

      setLodgedDate(parseDate(progressData.dateLodged));
      return onUpdateProgress({
        id: progressData?.id,
        callbackFn: (prog) => {
          setLodgedDate(parseDate(prog.dateLodged));
          if (prog.status === "COMPLETED") {
            setStatus("Signed");
          }
          if (!!prog.email) {
            setSendTo(prog.email);
          }
          checkResendTimeout(prog);
        },
      });
    }
  }, [progressData]);

  return (
    <>
      <Card
        className={`cert-step-1 acc-item ${done ? "done" : getCurrentStep() === "4" ? "current" : ""}`}
      >
        <Card.Header>
          <div className="acc-header" onClick={() => setCurrentStep(4)}>
            {done ? <StepIcon /> : <StepIconArrow />}

            <h3>Step 4: Sign Attestation Letter</h3>
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <div className="acc-content">
              <div className="step-block">
                <div className="df-row">
                  <div className="col-content">
                    <div className="step-content">
                      <strong className="step-title">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 0C14.748 0 19 4.25202 19 9.5C19 14.748 14.748 19 9.5 19C4.25202 19 0 14.748 0 9.5C0 4.25202 4.25202 0 9.5 0ZM8.39294 5.50081L11.2851 8.27419H4.29032C3.78085 8.27419 3.37097 8.68407 3.37097 9.19355V9.80645C3.37097 10.3159 3.78085 10.7258 4.29032 10.7258H11.2851L8.39294 13.4992C8.02137 13.8554 8.01371 14.4492 8.37762 14.8131L8.79899 15.2306C9.15907 15.5907 9.74133 15.5907 10.0976 15.2306L15.1808 10.1512C15.5409 9.79113 15.5409 9.20887 15.1808 8.85262L10.0976 3.76552C9.7375 3.40544 9.15524 3.40544 8.79899 3.76552L8.37762 4.18306C8.01371 4.55081 8.02137 5.14456 8.39294 5.50081Z"
                            fill="black"
                          />
                        </svg>
                        Instructions
                      </strong>

                      <div className="step-text">
                        <p>
                          Your Attestation Letter has been sent to the nominated
                          Director or owner of the organisation for signing.
                          When the Attestation Letter has been signed{" "}
                          <strong>
                            you will automatically progress to the next step.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-info">
                    <div className="card-lvl">
                      <h4 className="card-title">Attestation Letter</h4>

                      <ul className="att-list">
                        <li>
                          {/* <NativeTooltip description="Once the signing is done, please allow the app about 5 - 20 mins to update the status.">
                            <strong>Status:</strong>
                            <span>{status} {" "}</span>
                            <svg className="question" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" /></svg>
                          </NativeTooltip> */}
                          <strong>Status:</strong>
                          <span>{status} </span>
                        </li>

                        <li>
                          <strong>Date Lodged:</strong>
                          <span> {lodgedDate || "NA"}</span>
                        </li>
                      </ul>

                      <button
                        onClick={done ? handleNextStep : resend}
                        className="btn btn-warning"
                        disabled={timer !== "00:00" || !sendToTag}
                      >
                        {loading && (
                          <div className="resend-loading-spinner">
                            <Spinner />
                          </div>
                        )}{" "}
                        {done ? (
                          "Next Step"
                        ) : (
                          <>
                            Resend Letter {timer !== "00:00" && <>in {timer}</>}
                          </>
                        )}
                      </button>

                      <div className="resend-info">
                        <strong>Sending to:</strong>
                        {sendToTag ? (
                          <p
                            className="resend-email"
                            onClick={() => {
                              if (!done) {
                                setSendToTag(false);
                              }
                            }}
                          >
                            <svg
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                              <path
                                fillRule="evenodd"
                                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            {sendTo}
                          </p>
                        ) : (
                          <FormInput
                            controlId="email"
                            type="email"
                            name="email"
                            maxLength="240"
                            value={sendTo}
                            onChange={(event) => setSendTo(event.target.value)}
                            onBlur={() => setSendToTag(true)}
                            required
                            setFocus
                          />
                        )}
                      </div>

                      <div className={`form-validation mt-20 ${resendResult}`}>
                        <div className="validation-txt">
                          <p className="v-text success">
                            Attestation Letter has been resent successfully.
                          </p>
                          <p className="v-text error">
                            Resend failed. Please try again.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}
